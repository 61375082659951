$('[data-show-copy-url]').on("click",function (e) {
    var currentUrl = window.location.href;
    var textSpan = $(this).children('span');
    var textDiv = $(this).children('div');
    var textInput = $(this).children('input');

    textInput.val(currentUrl);
    textSpan.addClass('hide');
    textInput.removeClass('hide');
    textDiv.removeClass('hide');
    //textInput.focus();    
    //textInput.select();
    textInput.trigger("focus");
    textInput.trigger("select");
});