//$(document).ready(function () {
document.addEventListener('DOMContentLoaded', function () {
    // Primary, Secondary menu scripts
    function resetMenu() {
        $('div.sub-nav.sub-nav-box.level-0').removeClass('h-auto').removeAttr('style').addClass('isHidden');
        $('.country-language-selector button.cls__btn.targetIsShown').removeClass('targetIsShown');
        $('.country-language-selector div.cls__subnav:not(.isHidden)').removeAttr('style').addClass('isHidden');
        $('div[id^="Sub-"]:not(.hidden)').addClass('hidden');
        $('div.feat-content').removeClass('hidden');
        $('ul.sub-category-link.level-1 a.hasSubitems').removeClass('act-link');
    }

    // 1. Handling mouse events on a.mn__link.level-0.targetIsHidden
    const mainMenuItems = document.querySelectorAll('nav.main-nav li.mn__item.level-0');
    const secondaryMenuItems = document.querySelectorAll('nav.secondary-nav nav.main-nav li.mn__item.level-0');
    const mainContainer = document.getElementById('main-container');

    const handleMenuItem = function (menuItem) {
        const link = menuItem.querySelector('a.mn__link.level-0.targetIsHidden');
        const subNav = menuItem.querySelector('div.sub-nav.sub-nav-box.level-0');

        const setSubNavHeight = function () {
            const subNavHeight = subNav.scrollHeight;
            subNav.style.height = `${subNavHeight}px`;
        };

        try {
            link.addEventListener('mouseover', function () {
                resetMenu();  //set menu to default state on mouseover of main links              
                setSubNavHeight();
                subNav.classList.remove('isHidden');
                mainContainer.classList.add('overlayScreen');
            });

            menuItem.addEventListener('mouseleave', function () {
                subNav.classList.add('isHidden');
                subNav.style.height = ''; // Remove inline height when hidden
                mainContainer.classList.remove('overlayScreen');
            });

            subNav.addEventListener('mouseover', function () {
                setSubNavHeight();
                subNav.classList.remove('isHidden');
                mainContainer.classList.add('overlayScreen');
            });

            subNav.addEventListener('mouseout', function () {
                subNav.classList.add('isHidden');
                subNav.style.height = ''; // Remove inline height when hidden
                mainContainer.classList.remove('overlayScreen');
            });
        } catch (err) { }
    };
    mainMenuItems.forEach(handleMenuItem);
    secondaryMenuItems.forEach(handleMenuItem);

    // 2. Handling mouse events on a.hasSubitems under ul.sub-category-link.level-1
    $('ul.sub-category-link.level-1 a.hasSubitems').on('mouseover', function () {
        var id = $(this).attr('id');
        $('a.hasSubitems').removeClass('act-link');
        $(this).addClass('act-link');
        $('div.feat-content').addClass('hidden');
        $('div[id^="Sub-"]').addClass('hidden');
        //$('div#Sub-' + id).removeClass('hidden');
        $(this).closest('div.sub-nav.sub-nav-box.level-0').addClass('h-auto'); //sets height auto temporarily on parent sub-nav
        $('div#Sub-' + id).hide().removeClass('hidden').slideDown('slow', 'swing');
    });

    //3. Handling mouse events on anchors without hasSubitems
    $('.sub-nav--box-2 ul.sub-category-link.level-1 a:not(.hasSubitems),.sub-nav--box-2 .category-link a').on('mouseover', function () {
        if ($('div.feat-content').hasClass('hidden')) {
            // $('div.sub-nav.sub-nav-box.level-0.h-auto').removeClass('h-auto'); //removes height auto on parent sub-nav
            $('a.hasSubitems').removeClass('act-link');
            //$('div.feat-content').removeClass('hidden');
            $('div.feat-content').hide().removeClass('hidden').slideDown('slow', 'swing');
            $('div.sub-nav--box-3 div[id^="Sub-"]').addClass('hidden');
        }
    });

    //close menu on click of X icon
    $('.top-menu-close-btn').on('click', function (event) {
        event.preventDefault(); // Prevent default behavior of the button (if any)
        event.stopPropagation(); // Prevent event from propagating to parent elements
        $('div.sub-nav.sub-nav-box.level-0').removeClass('h-auto').removeAttr('style').addClass('isHidden');
        $('#main-container').removeClass('overlayScreen');
    });

    //Language selector Menu
    $(".country-language-selector").on('mouseenter', function () {
        const $subnav = $(".cls__subnav");
        $subnav.removeClass("isHidden");
        $("#main-container").addClass("overlayScreen");

        const subnavHeight = $subnav[0].scrollHeight;
        $subnav.css("height", subnavHeight + "px");

        $(".cls__btn").addClass("targetIsShown");
    });

    $(".cls__subnav").on('mouseleave', function () {
        $("#main-container").removeClass("overlayScreen");
        $(".cls__btn").removeClass("targetIsShown");
        $(this).addClass("isHidden");
        $(this).css("height", "");
    });

    $(".country-language-selector button.top-menu-close-btn").on('click', function (event) {
        event.preventDefault(); // Prevent default behavior of the button (if any)
        event.stopPropagation(); // Prevent event from propagating to parent elements

        $("#main-container").removeClass("overlayScreen");
        $(".cls__btn").removeClass("targetIsShown");
        $("#lang-menu.cls__subnav").addClass("isHidden");
        $("#lang-menu.cls__subnav").css("height", "");
    });

    

});