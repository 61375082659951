// Skype share
//(function (r, d, s) {
//    r.loadSkypeWebSdkAsync = r.loadSkypeWebSdkAsync || function (p) {
//        var js, sjs = d.getElementsByTagName(s)[0];
//        if (d.getElementById(p.id)) { return; }
//        js = d.createElement(s);
//        js.id = p.id;
//        js.src = p.scriptToLoad;
//        js.onload = p.callback
//        sjs.parentNode.insertBefore(js, sjs);
//    };
//    var p = {
//        scriptToLoad: 'https://swx.cdn.skype.com/shared/v/latest/skypewebsdk.js',
//        id: 'skype_web_sdk'
//    };
//    r.loadSkypeWebSdkAsync(p);
//})(window, document, 'script');


var shareSocial = {
    /**
	 * getUrl(e):
     * url = http://www.lorem.com/example/index.html
     * protocol = "http"
     * host = "lorem.com"
     * pathname = "/example/index.html"
	 */
    getUrl: function(part) {
        if (part === 'url') {
            var currentUrl = window.location.href;
            return currentUrl;
        } else if (part === 'protocol') {
            var currentProtocol = window.location.protocol;
            return currentProtocol;
        } else if (part === 'host') {
            var currentHost = window.location.host;
            return currentHost;
        } else if (part === 'pathname') {
            var currentPathname = window.location.pathname;
            return currentPathname;
        } else if (part === 'title') {
            var currentTitle = document.title;
            currentTitle = encodeURIComponent(currentTitle.trim());
            return currentTitle;
        } else if (part === 'lang') {
            var currentLang = document.documentElement.lang;
            return currentLang;
        } else {
            console.log('Touch last else from shareSocial.getUrl, not ok');
        }
    },

    /**
	 * getMeta(e):
     * title = 'News lorem ipsum'
	 */
    getMeta: function (part) {
        if (part === 'title') {
            var currentTitle = document.title;
            return currentTitle;
        } else {
            console.log('Touch last else from shareSocial.getMeta, not ok');
        }
    },

    /**
	 * injectFacebook
	 */
    injectFacebook: function() {
        var facebookLink = $('.ss__facebook');
        var host = shareSocial.getUrl('host');
        var protocol = shareSocial.getUrl('protocol');
        var pathname = shareSocial.getUrl('pathname');

        facebookLink.attr('href', 'https://facebook.com/sharer/sharer.php?u=' + host + pathname + '');
    },

    /**
	 * injectLinkedin
	 */
    injectLinkedin: function() {
        var linkedinLink = $('.ss__linkedin');
        var url = shareSocial.getUrl('url');

        linkedinLink.attr('href', 'https://www.linkedin.com/cws/share?url=' + url + '');
    },

    /**
	 * injectTwitter
	 */
    injectTwitter: function() {
        var twitterLink = $('.ss__twitter');
        var host = shareSocial.getUrl('host');
        var protocol = shareSocial.getUrl('protocol');
        var pathname = shareSocial.getUrl('pathname');
        var title = shareSocial.getUrl('title');

        twitterLink.attr('href',
            'https://twitter.com/intent/tweet/?text=' + title + '&url=' + protocol + '//' + host + pathname + '');
    },

    /**
	 * injectSkype
	 */
    injectSkype: function() {
        var skypeLink = $('.ss__skype');
        var url = shareSocial.getUrl('url');
        var title = shareSocial.getUrl('title');
        var lang = shareSocial.getUrl('lang');

        skypeLink.attr('data-href', '' + url + '');
        skypeLink.attr('data-text', '' + title + '');
        skypeLink.attr('data-lang', '' + lang + '');
    },

    /**
	 * injectGooglePlus
	 */
    injectGooglePlus: function() {
        var googlePlusLink = $('.ss__google-plus');
        var host = shareSocial.getUrl('host');
        var protocol = shareSocial.getUrl('protocol');
        var pathname = shareSocial.getUrl('pathname');

        googlePlusLink.attr('href', 'https://plus.google.com/share?url=' + protocol + '//' + host + pathname + '');
    },

    /**
	 * injectEmail
	 */
    injectEmail: function() {
        var emailLink = $('.ss__email');
        var url = shareSocial.getUrl('url');
        var title = shareSocial.getMeta('title');

        emailLink.attr('href', 'mailto:?subject=' + title + '&body=' + url + '');
    }
}

if ($('.ss__facebook').length) {
    shareSocial.injectFacebook();
}

if ($('.ss__linkedin').length) {
    shareSocial.injectLinkedin();
}

if ($('.ss__twitter').length) {
    shareSocial.injectTwitter();
}

if ($('.ss__skype').length) {
    shareSocial.injectSkype();
}

if ($('.ss__google-plus').length) {
    shareSocial.injectGooglePlus();
}

if ($('.ss__email').length) {
    shareSocial.injectEmail();
}