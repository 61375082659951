var hamburgerBtn = {
    /**
	 * activeHamburgerBtn
	 */
    activeHamburgerBtn: function () {
        var hamburgerContainer = $('.hamburger-button');
        hamburgerContainer.toggleClass('isActive');
    }
};

if ($('.hamburger-button').length) {
    $('.hamburger-button').on("click",function () {
        hamburgerBtn.activeHamburgerBtn();
    });
}