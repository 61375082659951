var jobListing = {
    selectedItems: null,

    /**
	 * initJobListing
	 */
    initJobListing: function () {
        var selectedItems = $('#contryul > li button.selected'),
            firstItemCountry = selectedItems.text(),
            position = 0;

        jobListing.selectedItems = selectedItems.closest('li');

        jobListing.selectedItems.addClass('selected');

        position = ((jobListing.selectedItems.index() + 1) * jobListing.selectedItems.height() - $('.jsf__list').height());

        $('.jsf__list').scrollTop(position);

        $('.jsf__value').replaceWith('<span class="jsf__value">' + firstItemCountry + '</span>');
    },



    /**
	 * Update value
	 */
    updateValue: function ($el) {
        var currentBtn = $el;
        var btnData = currentBtn.attr('data');
        var btnText = currentBtn.text();

        $('.jsf__secondary-button').removeClass('selected');
        currentBtn.addClass('selected');
        $('.jsf__value').replaceWith('<span class="jsf__value">' + btnText + '</span>');

        $('#countryselect option').removeAttr('selected');
        $('#countryselect option[value="' + btnData + '"]').attr("selected", "selected");
    },



    /**
	 * Toggle list
	 */
    toggleList: function () {
        $('.jsf_countrylist').toggleClass('isOpen');
        $('.jsf__main-button').toggleClass('isOpen');
        $('.jsf__item').toggleClass('isOpen');
        $('.jsf__list').toggleClass('open');
    }
};



if ($('.jsf_countrylist').length) {
    jobListing.initJobListing();
    var position = 0;

    $('#contryul > li > button').on("click",function (e) {
        jobListing.updateValue($(this));
        jobListing.toggleList();
        $(this).closest('ul').find('.selected').removeClass('selected');
        jobListing.selectedItems = $(this).closest('li');
        jobListing.selectedItems.addClass('selected');
    });

    $('.jsf__main-button').on("click",function () {
        jobListing.toggleList();
    });

    $(document).on("mouseup",function (e) {
        var container = $('.jsf_countrylist')

        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.jsf_countrylist').removeClass('isOpen');
            $('.jsf__main-button').removeClass('isOpen');
            $('.jsf__item').removeClass('isOpen');
            $('.jsf__list').removeClass('open');
        }
    });

    $('.jsf_countrylist').on("keydown",function (e) {
        console.log(e.which);

        if (e.which == 38) {
            e.preventDefault();
            if (!jobListing.selectedItems.is(':first-child')) {
                jobListing.selectedItems.removeClass('selected');
                jobListing.selectedItems = jobListing.selectedItems.prev();
                jobListing.selectedItems.addClass('selected');
                position = jobListing.selectedItems.index() * jobListing.selectedItems.height();

                jobListing.updateValue(jobListing.selectedItems.find('button'));

                if (position < $('.jsf__list').scrollTop()) {
                    $('.jsf__list').scrollTop(position);
                }
            }

            return false;
        } else if (e.which == 40) {
            e.preventDefault();

            if (!jobListing.selectedItems.is(':last-child')) {
                jobListing.selectedItems.removeClass('selected');
                jobListing.selectedItems = jobListing.selectedItems.next();
                jobListing.selectedItems.addClass('selected');
                jobListing.updateValue(jobListing.selectedItems.find('button'));
                position = ((jobListing.selectedItems.index() + 1) * jobListing.selectedItems.height() - $('.jsf__list').height());

                if ($('.jsf__list').scrollTop() < position) {
                    $('.jsf__list').scrollTop(position);
                }


            }

            return false;
        } else if (e.which == 27) {
            $('.jsf__list').removeClass('open');
        } else if (e.which == 9) {
            $('.jsf_countrylist').removeClass('isOpen');
            $('.jsf__main-button').removeClass('isOpen');
            $('.jsf__item').removeClass('isOpen');
            $('.jsf__list').removeClass('open');
        }
    });
}