$(document).ready(function () {
    var url = new URL(window.location.href);
    var params = url.searchParams;
    var searchKey = params.get('query');
    if (searchKey == null) {
        searchKey = '';
    }
    var countryKey = params.get('country');
    if (countryKey == null) {
        countryKey = '';
    }

    function getSelectedCheckboxes(name) {
        var lstCheckboxes = $('input[name="' + name + '"]:checked');
        var query = '';
        if (lstCheckboxes.length > 0) {
            for (var i = 0; i < lstCheckboxes.length; i++) {
                query = query + '|' + lstCheckboxes[i].value;
            }
            query = query.slice(1);
        }
        return query;
    }

    function updateShowingMessage(NoOfResults) {
        var msg = $("#totalResultMessageJS").val();
        var msgWQuery = $("#totalResultQueryMessageJS").val();
        if (searchKey == '') {
            msg = msg.replace('{0}', NoOfResults);
            $("#dict-title").html(msg);
        }
        else {
            msgWQuery = msgWQuery.replace('{0}', NoOfResults).replace('{1}', searchKey);
            $("#dict-title").html(msgWQuery);
        }
    }

    //Country checkbox click
    $('#checkbox-list1 input[name="country"]').on('click', function () {
        //alert("check unchecked");
        var country = getSelectedCheckboxes('country');
        var path = document.location.origin + "/api/Sitecore/Career/AjaxJobList?query=" + encodeURIComponent(searchKey) + "&country=" + country;
        $.ajax({
            url: path,
            contentType: "application/json",
            type: "GET",
            context: this,
            beforeSend: function () {
                $("#spinner-animation-wrapper").show();
            },
            success: function (data) {
                //console.log("success", data);
                // console.log("Jobs", data.CurrentJobs);
                bindJobData(data.CurrentJobs);
                bindPagination(data);
                updateShowingMessage(data.TotalResults);
            },
            complete: function () {
                $("#spinner-animation-wrapper").hide();
                checkWindowSizeandchildWidget(); updateOverflow();
                //document.querySelector(".ft-breadcrumb").scrollIntoView({ behavior: "smooth" });
                scrollToSearchTitle();
            },
            error: function (data) {
                console.log("error", data);
            }
        });
    });

    //City checkbox click
    $('input[name="city"]').on('click', function () {
        var city = getSelectedCheckboxes('city');
        var country = getSelectedCheckboxes('country');
        var path = document.location.origin + "/api/Sitecore/Career/AjaxJobList?query=" + encodeURIComponent(searchKey) + "&location=" + city + "&country=" + country;
        $.ajax({
            url: path,
            contentType: "application/json",
            type: "GET",
            context: this,
            beforeSend: function () {
                $("#spinner-animation-wrapper").show();
            },
            success: function (data) {
                //console.log("success", data);
                bindJobData(data.CurrentJobs);
                bindPagination(data);
                updateShowingMessage(data.TotalResults);
            },
            complete: function () {
                $("#spinner-animation-wrapper").hide();
                checkWindowSizeandchildWidget(); updateOverflow();
                //document.querySelector(".ft-breadcrumb").scrollIntoView({ behavior: "smooth" });
                scrollToSearchTitle();
            },
            error: function (data) {
                console.log("error", data);
            }
        });
    });

    //Pager link click
    $('#pagination').on('click', 'a[name="page"]', function () {
        var page = $(this).data("value");
        var country = getSelectedCheckboxes('country');
        var city = getSelectedCheckboxes('city');
        var path = document.location.origin + "/api/Sitecore/Career/AjaxJobList?query=" + encodeURIComponent(searchKey) + "&country=" + country + "&page=" + page + "&location=" + city;
        $.ajax({
            url: path,
            contentType: "application/json",
            type: "GET",
            context: this,
            beforeSend: function () {
                $("#spinner-animation-wrapper").show();
            },
            success: function (data) {
                //console.log("success", data);
                bindJobData(data.CurrentJobs);
                bindPagination(data);
                updateShowingMessage(data.TotalResults);
            },
            complete: function () {
                $("#spinner-animation-wrapper").hide();
                checkWindowSizeandchildWidget(); updateOverflow();
                //document.querySelector(".ft-breadcrumb").scrollIntoView({ behavior: "smooth" });
                scrollToSearchTitle();
            },
            error: function (data) {
                console.log("error", data);
            }
        });
    });

    function bindJobData(jobs) {
        var jobsHTML = "";
        var dataJson = $("#dataLayer").val();
        if (jobs.length > 0) {
            for (var i = 0; i < jobs.length; i++) {
                var jsonData = dataJson !== "" ? dataJson.replace("{Url}", jobs[i].ApplyUrl).replace("{Text}", jobs[i].Title).replace("{Job_City}", jobs[i].Location).replace("{Job_Country}", jobs[i].CountryName) : "";
                var jobDatalayer = jsonData !== "" ? jsonData : "";

                jobsHTML = jobsHTML + '<div class="press-list-layout pressResult"> <div class="press-result-frame"> <div class="press-result-row">';
                if (jobs[i].ApplyUrl.includes("careers-louisberger.icims.com")) {
                    jobsHTML = jobsHTML + '<a href="javascript:void(0);" data-toggle="modal" onclick="louisBerger(' + jobs[i].ApplyUrl + ')" data-value="' + jobDatalayer + '" class="career-result-link"><div>'
                        + '<h2 class="typo__24_20" >' + jobs[i].Title + '<img class="icon-external-link m-l-10p" src="/-/media/Icons/General/icn-career-link" alt="Link"></h2><div class="office-group"><div class="office-address m-t-1">'
                        + '<span class="icon job-location"></span><div class="text text-locations overflow-container">' + jobs[i].Location + '</div></div></div></div></a>';
                }
                else {
                    jobsHTML = jobsHTML + '<a href="' + jobs[i].ApplyUrl + '" data-value="' + jobDatalayer + '" target="_blank" class="career-result-link"><div>'
                        + '<h2 class="typo__24_20" >' + jobs[i].Title + '<img class="icon-external-link m-l-10p" src="/-/media/Icons/General/icn-career-link" alt="Link"></h2><div class="office-group"><div class="office-address m-t-1">'
                        + '<span class="icon job-location"></span><div class="text text-locations overflow-container">' + jobs[i].Location + '</div></div></div></div></a>';
                }
                jobsHTML = jobsHTML + '</div></div></div>';
            }
        }
        else {
            jobsHTML = '<div class="no-results-container"><img src="/-/media/Icons/General/icn-no-search-results" alt="Icon" class="img-responsive" /><h2>' + $("#noResults").val() + '</h2><p class="m-b-1">' + $("#searchAgain").val() + '</p></div>';
        }

        document.getElementById("jobscontainer").innerHTML = jobsHTML;
    }

    $(document).on('click', '.career-result-link', function () {
        // this will get data-value & convert it to json object
        var dlValue = $(this).data("value");
        dlValue = typeof dlValue === 'string' ? dlValue.replace(/'/g, '"') : {};
        dlValue = JSON.parse(dlValue);
        //console.log("jsonParse:", dlValue);
        dataLayer.push(dlValue);

        $.ajax({
            url: document.location.origin + "/api/Sitecore/SitecoreGoals/CustomGoals?goalId=" + encodeURIComponent("{11879F3C-AD6D-43CB-9229-02A47D62E3AE}"),
            contentType: "application/json",
            type: "GET",
            context: this,
            success: function (data) {
                console.log("Success", data);
            },
            error: function (data) {
                console.log("error", data);
            }
        });
    });

    function bindPagination(data) {
        var paginationHtml = '<h3 class="sr-only">' + $("#searchNavigationText").val() + '</h3>';
        if (data.CurrentPage != 1 && !(data.CurrentPage < 1)) {
            paginationHtml = paginationHtml + '<div class="pagelist prv-arw"><a class="pageprev" href="javascript:;" name="page" data-value="' + (data.CurrentPage - 1) + '"><span></span></a></div>';
        }
        if (data.WindowStartPage > 1) {
            paginationHtml = paginationHtml + '<div class="pagelist"><a class="pageprev" name="page" href="javascript:;" data-value="1">1</a></div>';
        }
        if (data.WindowStartPage > 2) {
            paginationHtml = paginationHtml + '<div class="pagesuspend"><a class="pageprev" href="javascript:;">...</a></div>';
        }
        for (let p = data.WindowStartPage; p <= data.WindowEndPage; p++) {
            if (p == data.CurrentPage) {
                paginationHtml = paginationHtml + '<div class="pagelist current"><a class="pageprev" name="page" href="javascript:;" data-value="' + p + '">' + p + '</a></div>';
                // console.log("Page" + data.CurrentPage);
            }
            else {
                paginationHtml = paginationHtml + '<div class="pagelist"><a class="pageprev" name="page" href="javascript:;" data-value="' + p + '">' + p + '</a></div>';
                // console.log("Page" + data.CurrentPage);
            }
        }
        if (data.WindowEndPage < data.TotalPages - 1) {
            paginationHtml = paginationHtml + '<div class="pagesuspend"><a class="pageprev" href="javascript:;">...</a></div>';
        }
        if (data.WindowEndPage < data.TotalPages) {
            paginationHtml = paginationHtml + '<div class="pagelist"><a class="pageprev" href="javascript:;" name="page" data-value="' + data.TotalPages + '">' + data.TotalPages + '</a></div>';
        }
        if (data.CurrentPage != data.TotalPages) {
            paginationHtml = paginationHtml + '<div class="pagelist nxt-arw"><a class="pagenext" href="javascript:;" name="page" data-value="' + (data.CurrentPage + 1) + '"><span></span></a></div>';
        }

        //console.log(paginationHtml);
        $("#pagination").html(paginationHtml);
    }

    $("#clearFilters").on('click', function () {
        //alert("clear all clicked");
        var path = document.location.origin + "/api/Sitecore/Career/AjaxJobList?query=" + encodeURIComponent(searchKey);
        $.ajax({
            url: path,
            contentType: "application/json",
            type: "GET",
            context: this,
            beforeSend: function () {
                $("#spinner-animation-wrapper").show();
            },
            success: function (data) {
                //console.log("success", data);
                // console.log("Jobs", data.CurrentJobs);
                bindJobData(data.CurrentJobs);
                bindPagination(data);
                updateShowingMessage(data.TotalResults);
            },
            complete: function () {
                $("#spinner-animation-wrapper").hide();
                checkWindowSizeandchildWidget(); updateOverflow();
                //document.querySelector(".ft-breadcrumb").scrollIntoView({ behavior: "smooth" });
                scrollToSearchTitle();
            },
            error: function (data) {
                console.log("error", data);
            }
        });
    });

    $("#dict-title").on('click', 'a[id="cross"]', function () {
        var url = window.location.origin + window.location.pathname;
        if (countryKey != '') {
            window.location.href = url + "?country=" + countryKey;
        }
        else {
            window.location.href = url;
        }
    });

    //function updateOverflow() {
    //    const containers = document.querySelectorAll(".overflow-container");
    //    //  console.log(containers);
    //    containers.forEach(function (container) {
    //        if (container.scrollHeight > container.clientHeight) {
    //            const showMoreButton = document.createElement("div");
    //            showMoreButton.className = "show-more";
    //            showMoreButton.innerText = $("#viewAll").val();
    //            showMoreButton.addEventListener("click", function (event) {
    //                event.preventDefault();
    //                if (container.style.maxHeight === "none") {
    //                    showMoreButton.className = "show-more isClosed";
    //                    // If already expanded, show less
    //                    container.style.maxHeight = "20px";
    //                    // container.style.maxHeight = container.clientHeight + "20px";
    //                    showMoreButton.innerText = $("#viewAll").val();
    //                } else {
    //                    // If collapsed, show more
    //                    showMoreButton.className = "show-more isOpen";
    //                    container.style.maxHeight = "none";
    //                    showMoreButton.innerText = $("#viewLess").val();
    //                }
    //            });
    //            container.appendChild(showMoreButton);
    //        }
    //    });
    //}
    //window.addEventListener('load', updateOverflow);  //execute show-more function on page load
});



