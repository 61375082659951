(function ($) {
    $(function () {
        var daysToExpire = 7;
        var expiration = new Date();
        expiration.setTime(expiration.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));

        // Client Resolution : Width
        var screenWidth = "screenWidth";
        document.cookie = screenWidth + "=" + window.screen.availWidth + "; expires=" + expiration + "; path=/" + "; SameSite=None" + "; Secure";

        // Client Resolution : Height
        var screenHeight = "screenHeight";
        document.cookie = screenHeight + "=" + window.screen.availHeight + "; expires=" + expiration + "; path=/" + "; SameSite=None" + "; Secure";
    });
})(jQuery);