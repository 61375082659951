var _namespace = 'SideNav';

/**
 * Constructor method
 */
var SideNav = this.SideNav = function (el, options) {
    this.$el = $(el);
    this.$subNav = this.$el.next();
    this.$tray = $('.hamburger-button');
    this.$openedClass = 'mobile-opened';
    this.$subnavOpenedClass = 'sub-nav-opened';
    this.$mobileNav = this.$el.closest('.desktop-header');
    this.$backLinkContainer = this.$subNav.hasClass('cls__subnav') ? this.$subNav.find('> .row > .back-link') : this.$subNav.find('> ul > .back-link');
    this.$topCategoryLink = this.$subNav.hasClass('cls__subnav') ? this.$subNav.find('> .row > .top-category-link > a') : this.$subNav.find('> ul > .top-category-link > a');
    this.$backLink = this.$backLinkContainer.find('a');

    this._init();

    // set data on the object
    this.$el.data(_namespace, this);
};


SideNav.prototype = {
    _init: function () {
        this.$subNav.addClass('mobile-sub');
        this.$el.addClass('subnav-trigger');

        if (!this.$backLinkContainer.hasClass('no-text-change')) {
            this.$backLink.text(this.$el.text());
        }

        if (this.$topCategoryLink.length) {
            this.$topCategoryLink.attr('href', this.$el.attr('href')).text(this.$el.text())
        }

        this._bindEvent();
    },

    _bindEvent: function () {
        var self = this;

        this.$el.on('click.' + _namespace, function (e) {
            e.preventDefault();

            self.openMenu();
        });

        this.$backLink.on('click.' + _namespace, function (e) {
            e.preventDefault();

            self.closeMenu();
        });
    },

    openMenu: function () {
        this.$el.addClass(this.$openedClass);
        this.$mobileNav.scrollTop(0).addClass(this.$subnavOpenedClass);
    },

    closeMenu: function () {
        this.$el.removeClass(this.$openedClass);
        this.$mobileNav.removeClass(this.$subnavOpenedClass);
    },

    checkTransition: function ($el, fn) {
        if ($.support.transition) {
            $el.one($.support.transition.end, function (e) {
                fn();
            });
        } else {
            fn();
        }
    },

    unset: function () {
        this.$subNav.removeClass('mobile-sub')
        this.$el.removeData(_namespace).removeClass(this.$openedClass + ' subnav-trigger').off('click.' + _namespace);
        this.$mobileNav.removeClass(this.$subnavOpenedClass);
        this.$backLink.off('click.' + _namespace)
    }
}

// Plugin declaration/assignment
$.fn[_namespace] = function (options, arguments) {
    var args = arguments,
        method = null;
    options || (options = {});

    // if options is a string/method - set method and reset options to args[1]
    if (typeof options === 'string') {
        method = options;

        if (typeof args != 'undefined') {
            args = Array.prototype.slice.call(args, 0);
        } else {
            args = [];
        }
    }

    return this.each(function () {
        var data = $(this).data(_namespace);

        if (!data) {
            data = new SideNav(this, $.extend({}, options));
        }

        if (method) {
            if (SideNav.prototype[method]) {
                data[method].apply(data, args);
            } else {
                console.error('Method ' + method + ' does not exist in ' + _namespace);
            }
        }
    });
};

/**
 * Data api definition/setup/instantiation
 */
$('document').ready(function () {
    var $languageButton = $('.country-language-selector .cls__btn'),
        $body = $('body'),
        $tray = $('.hamburger-button'),
        $secondaryTrigger = $('.secondary-nav li.level-1 > [data-toggle-next-element]'),
        //$secondaryTrigger = $('li.level-1 > [data-toggle-next-element]'),
    $mainMenuSub = $('.main-nav li.level-1 > [data-toggle-next-element]');
    //$mainMenuSub = $('li.level-1 > [data-toggle-next-element]'),
    //$countryLanguageButton = $('.title-country-language .tcl__item');

    Respond.to({
        'media': '(max-width: 1199px)',
        'namespace': '1200_mobile_nav',
        'fallback': 'else',
        'if': function () {
            $tray.on('click.tray', function (e) {
                e.preventDefault();

                if ($body.hasClass('sideNavIsOpen')) {
                    $body.removeClass('sideNavIsOpen');
                    $('.mobile-opened').removeClass('mobile-opened');
                    $('.sub-nav-opened').removeClass('sub-nav-opened');
                } else {
                    $body.addClass('sideNavIsOpen');
                }
            });


            $('.main-nav .mn__item.level-0 a.mn__link, .secondary-nav a.sn__link').on("click", function (clkevent) {
                clkevent.preventDefault();

                //Below function is used to cheeck if the main link has second level navigation, if not then navigate user directly to respective page on click
                if (($(this).next('div.collapse')[0])) {
                    // alert('this link has 2nd level menu')
                } else {

                    var getmnLnk = $(this).attr('href');
                    window.location = getmnLnk;
                    //alert('this link does not have 2nd level menu');
                }

                //Below function checks if the main link has class arw_up and onclick navigate user directly to respective page.
                if (($(this).hasClass("arw_up"))) {
                    //alert('hi');
                    var getmnLnk = $(this).attr('href');
                    //alert(getmnLnk);
                    window.location = getmnLnk;
                }

                //below events happen on click of main link everytime
                $(this).not('.arw_rgt').removeClass('arw_dwn').addClass('arw_up');  //exclude element having arw_rgt while removing and adding class
                $(this).siblings("div").addClass('in'); //show second-level menu by adding class "in" on sibling div sub-nav/subnav
                $('.main-nav .mn__item.level-0 a.mn__link, .secondary-nav a.sn__link').not(this).siblings("div").removeClass('in'); //hide all other second-level menu
                $('.main-nav .mn__item.level-0 a.mn__link, .secondary-nav a.sn__link').not(this).not('.arw_rgt').removeClass('arw_up').addClass('arw_dwn'); //exclude arw_right while removing and adding class
                $("span.arwToglBox").remove(); //remove temporary span created on any other mobile menu created by this function
                $(this).before('<span class="arwToglBox"></span>'); //create a temporary span just for this clicked section in mobile menu

                //Below function allows closing of expanded mobile menu to collapse/close on click of temporary invisible arrow which is placed on right of main link
                $("span.arwToglBox").on("click",function () {
                    $(this).siblings("div.collapse").removeClass('in');
                    $(this).siblings("a.arw_up").not('.arw_rgt').removeClass('arw_up').addClass('arw_dwn');
                    $("span.arwToglBox").remove();
                    //alert('hi');
                });


            });




            //$countryLanguageButton.on('click.mobile', function (e) {

            //    var container = $(this).find('a');

            //    if (!container.is(e.target) && container.has(e.target).length === 0) {
            //        e.preventDefault();

            //        $(this).toggleClass('show');
            //    }

            //});


            // $('.main-nav .mn__item.level-0 a.mn__link, .secondary-nav a.sn__link').addClass('arw_dwn');

            $('.main-nav .sub-nav.level-0, .secondary-nav .subnav').addClass('collapse');

            $('.main-nav .mn__item.level-0 a.mn__link, .secondary-nav a.sn__link').each(function () {
                if (($(this).next('div.collapse')[0])) {
                    // alert('this link has 2nd level menu')
                    $(this).addClass('arw_dwn');
                } else {
                    // alert('this link does not have 2nd level menu')
                    $(this).addClass('arw_rgt');
                }

            })

            $languageButton.SideNav();
            $secondaryTrigger.SideNav();
            $mainMenuSub.SideNav();
        },
        'else': function () {
            $tray.off('click.tray');
            $body.removeClass('sideNavIsOpen');
            //$countryLanguageButton.off('click.mobile');
            $('.overlayIsActive').removeClass('overlayIsActive')

            if ($secondaryTrigger.data('SideNav')) {
                $secondaryTrigger.SideNav('unset');
                $languageButton.SideNav('unset');
                $mainMenuSub.SideNav('unset');
            }
            $('.main-nav .sub-nav.level-0, .secondary-nav .subnav').removeClass('collapse');
            $("span.arwToglBox").remove();
        }
    });






});

