var searchResults = {
    /**
	 * toggleFilters
	 * Only visible on mobile
	 */
    toggleFilters: function (e) {
        var eTarget = $(e.currentTarget);
        var filterContainer = eTarget.next();
       // console.log(eTarget);
        //console.log(filterContainer);
        //if ($(filterContainer).hasClass('visible-md-block')) {
        //    $(filterContainer).removeClass('visible-md-block visible-lg-block');
        //    eTarget.addClass('arrowIsDown');
        //} else {
        //    $(filterContainer).addClass('visible-md-block visible-lg-block');
        //    eTarget.removeClass('arrowIsDown');
        //}

       
        // 197 - Mobile experience issues - filtering
        if ($(filterContainer).attr('style')) {
            $(filterContainer).removeAttr("style");
            $(filterContainer).removeClass('visible-md-block visible-lg-block');
            eTarget.addClass('arrowIsDown');
          //  console.log('if has style');
        } else {
            $(filterContainer).addClass('visible-md-block visible-lg-block');
            $(filterContainer).hide();
            eTarget.removeClass('arrowIsDown');
           // console.log('inside else');
        }        
        
    },


    /**
	 * updateSearchValue
	 */
    updateSearchValue: function (value, path) {
        $('form[data-inject-action-when-typing]').attr('action', path + '#q=' + value + '&sort=relevancy');
    }
};


$(window).on("load",function () {
    if ($('.coveo-facet-container').length) {
        $('[data-filter-by]').on("click",function (e) {
            searchResults.toggleFilters(e);
        });
    }

    if ($('[data-inject-action-when-typing]').length && $('#inputSearchBox').length) {
        var path = $('#searchpath').val();
        $("#inputSearchBox").on("change paste keyup", function () {
            searchResults.updateSearchValue($(this).val(), path);
        });
    }
});


