/**
 * showTarget
 * Usefull if you want show an element not close
 * add attr data-show-target to a link with an anchor
 * the target should have an iD equal to the anchor
 */
function showTarget() {
    //TODO: aria-expanded

    if ($('a[data-show-target]').length) {
        $('a[data-show-target]').each(function (index) {
            var targetId = $(this).attr('href');
            $(this).addClass('targetIsHidden');
            $(targetId).hide();
            $(targetId).addClass('isHidden');
            $(targetId).attr('tabindex', '0');
        });

        $('a[data-show-target]').on("click",function () {
            var targetId = $(this).attr('href');
            var thisLink = $(this);
            $(this).toggleClass('targetIsHidden');
            $(this).toggleClass('targetIsShown');
            $(targetId).toggle();
            $(targetId).toggleClass('isHidden');
            $(targetId).toggleClass('isShown');
        });
    }
}

showTarget();