var contact = {
    /**
     * injectCountry
     */
    injectCountry: function ($input) {
        var currentPathname = window.location.pathname;
        var currentPathname = currentPathname.toLowerCase();
        var userLang = navigator.language || navigator.userLanguage;

        if (currentPathname.indexOf("-cn/") >= 0) {
            var country = "China"
        } else if (currentPathname.indexOf("-in/") >= 0) {
            var country = "India"
        } else if (currentPathname.indexOf("-jp/") >= 0) {
            var country = "Japan"
        } else if (currentPathname.indexOf("-kw/") >= 0) {
            var country = "Kuwait"
        } else if (currentPathname.indexOf("-om/") >= 0) {
            var country = "Oman"
        } else if (currentPathname.indexOf("-ph/") >= 0) {
            var country = "Philippines"
        } else if (currentPathname.indexOf("-qa/") >= 0) {
            var country = "Qatar"
        } else if (currentPathname.indexOf("-sa/") >= 0) {
            var country = "Saudi Arabia"
        } else if (currentPathname.indexOf("-sg/") >= 0) {
            var country = "Singapore"
        } else if (currentPathname.indexOf("-kp/") >= 0) {
            var country = "Korea"
        } else if (currentPathname.indexOf("-th/") >= 0) {
            var country = "Thailand"
        } else if (currentPathname.indexOf("-tr/") >= 0) {
            var country = "Turkey"
        } else if (currentPathname.indexOf("-ae/") >= 0) {
            var country = "United Arab Emirates"
        } else if (currentPathname.indexOf("-vn/") >= 0) {
            var country = "Viet Nam"
        } else if (currentPathname.indexOf("-dk/") >= 0) {
            var country = "Denmark"
        } else if (currentPathname.indexOf("-fi/") >= 0) {
            var country = "Finland"
        } else if (currentPathname.indexOf("-fr/") >= 0) {
            var country = "France"
        } else if (currentPathname.indexOf("-de/") >= 0) {
            var country = "Germany"
        } else if (currentPathname.indexOf("-gb/") >= 0) {
            var country = "United Kingdom"
        } else if (currentPathname.indexOf("-no/") >= 0) {
            var country = "Norway"
        } else if (currentPathname.indexOf("-pl/") >= 0) {
            var country = "Poland"
        } else if (currentPathname.indexOf("-ro/") >= 0) {
            var country = "Romania"
        } else if (currentPathname.indexOf("-rs/") >= 0) {
            var country = "Serbia"
        } else if (currentPathname.indexOf("-se/") >= 0) {
            var country = "Sweden"
        } else if (currentPathname.indexOf("-ch/") >= 0) {
            var country = "Switzerland"
        } else if (currentPathname.indexOf("-za/") >= 0) {
            var country = "South Africa"
        } else if (currentPathname.indexOf("-ca/") >= 0) {
            var country = "Canada"
        } else if (currentPathname.indexOf("-cl/") >= 0) {
            var country = "Chile"
        } else if (currentPathname.indexOf("-co/") >= 0) {
            var country = "Colombia"
        } else if (currentPathname.indexOf("-mx/") >= 0) {
            var country = "Mexico"
        } else if (currentPathname.indexOf("-pe/") >= 0) {
            var country = "Peru"
        } else if (currentPathname.indexOf("-tt/") >= 0) {
            var country = "Trinidad And Tobago"
        } else if (currentPathname.indexOf("-us/") >= 0) {
            var country = "United States"
        } else if (currentPathname.indexOf("-au/") >= 0) {
            var country = "Australia"
        } else if (currentPathname.indexOf("-nz/") >= 0) {
            var country = "New Zealand"
        } else {
            var country = "Canada"
        }

        var countryselect = $input.find('option[value="' + country + '"]');

        countryselect.attr("selected", true);
    }
};

if ($('#wffmc14f0f815c4d4dfab7047845fc5123b6_Sections_0__Fields_4__Value').length) {
    contact.injectCountry($('#wffmc14f0f815c4d4dfab7047845fc5123b6_Sections_0__Fields_4__Value'));
}



(function ($) {

    var _namespace = 'ContactUsForm';

    /**
     * Constructor method
     */
    var ContactUsForm = this.ContactUsForm = function (el, options) {
        this.$el = $(el);

        this.options = options;
        this.$modal = this.$el.closest('.contact-us-modal');
        this.$modalForm = $("#modal-form");
        this.$button = this.$el.find(this.options.button);
        this.$container = !this.$modal.length ? this.$el.find(this.options.containerClass) : this.$modalForm.find('.modal-body');
        this.loadedClass = this.options.loadedclass;
        this.url = this.$button.attr('href');
        this.busy = false;
        this.allContactForm = $('.js-contact-form').not(el);
        this.allButtons = this.allContactForm.find('.btn:not(.btn-ghost)');
        this.category = $('#categories');
        this.subCategory = $('#sub-categories');

        this._init();

        // set data on the object
        this.$el.data(_namespace, this);
    };

    ContactUsForm.defaults = {
        'button': '.btn',
        'containerClass': '.js-form-container',
        'loadedclass': 'form-loaded'
    };

    ContactUsForm.prototype = {
        _init: function () {
            var $div = $('<div>', {
                class: this.options.containerClass.replace('.', '')
            }),
                $border = $('<div>', {
                    class: 'active-border'
                }),
                _self = this;

            if (!this.$container.length && !this.$modal.length) {
                this.$el.append($div);
                this.$el.append($border);
                this.$container = this.$el.find(this.options.containerClass);
                this.$border = this.$el.find('.active-border');

                setTimeout(function () {
                    _self.$border.css({ 'top': _self.$el.outerHeight() - 2 });
                }, 300)

            }

            this.$container.addClass('closed');

            this._bindEvent();

            this.$button.attr('loading-text', this.$button.attr('loading-text') + this.$button.first().text());

            $('document').trigger('ready.' + _namespace);
        },

        _setPosition: function () {
            var offsetLeft = this.$el.offset().left;

            this.$container.css({ 'left': '-' + offsetLeft + 'px' });
        },

        _bindEvent: function () {
            var _self = this;

            this.$button.on('click.' + _namespace, function (e) {
                e.preventDefault();

                dataLayer.push({
                    'event': 'interaction',
                    'eventCategory': 'contact us',
                    'eventAction': 'content intent',
                    'eventLabel': _self.$button.data('name')
                });

                var flag = false,
                    formActive,
                    $that = $(this);

                if (!_self.busy) {
                    if (_self.$el.hasClass(_self.loadedClass)) {
                        _self.hide();
                    } else {
                        _self.allContactForm.each(function () {
                            if ($(this).hasClass(_self.loadedClass)) {
                                flag = true;
                                formActive = $(this);
                            }
                        });

                        if (flag) {
                            formActive.ContactUsForm('hide');
                        }


                        if (!$(this).attr('loading-text').includes('undefined')) {

                            $(this).data('text', $(this).html()).html($(this).attr('loading-text')).attr('disabled', 'disabled');

                            _self.allButtons.each(function () {
                                $(this).attr('disabled', 'disabled');
                            });
                        }
                                             


                        setTimeout(function () {
                            $.ajax({
                                url: $that.attr('href'),
                                method: 'GET',
                                data: { id: _self.$button.data('id') },
                                dataType: 'html',
                                cache: false,
                                success: function (html) {
                                    $that.html($that.data('text')).removeAttr('disabled');

                                    _self.allButtons.each(function () {
                                        $(this).removeAttr('disabled');
                                    });

                                    if (flag) {
                                        _self.checkTransition(formActive, function () {
                                            _self._renderForm(html);
                                        })
                                    } else {
                                        _self._renderForm(html);
                                    }
                                }
                            }, 1000);

                        })

                    }
                }
            });

            if (!_self.$modal.length) {
                $(window).on('resize.' + _namespace, function () {
                    if (_self.$el.hasClass(_self.loadedClass)) {
                        _self._setPosition();
                        _self.$border.css({ 'top': this.$el.height() });
                    }
                });
            }
        },

        _renderForm: function (html) {
            this.$container.html(html);

            if (!this.$modal.length) {
                this._setPosition();
            }
            this.$form = this.$container.find('form');
            this._fillField();
            this.show();
        },

        _fillField: function () {
            var $formContainer = this.$container.find('.js-form-col'),
                $personContact = this.$container.find('.personContact input').last();

            $('.js-contact-name span').text($formContainer.data('name'));
            $personContact.val($formContainer.data('personcontact'));
            $formContainer.find('select option[value="' + $formContainer.data('country') + '"]').attr('selected', 'selected');

        

           
        },

        show: function () {
            var formHeight = this.$container.children().first().outerHeight(true),
                _self = this;

            if (!_self.$modal.length) {
                this.busy = true;

                this.$el.addClass(this.loadedClass)
                this.$container.css('height', formHeight).addClass('transition');
                this.$button.toggleClass('hide');

                setTimeout(function () {
                    _self.$container.removeClass('closed');

                    //override WFFM Ajax Success Function 
                    window.jQuery.wffm.wffmForm.prototype.formSubmitSuccess = function (form, res) {
                        var $form = $(form),
                            subject;

                        // Start customization
                        $form.find('label').each(function () {
                            if ($(this).text().toLowerCase() === 'subject') {
                                subject = $(this).next().val();
                            }
                        });

                        if (typeof dataLayer != "undefined") {
                            dataLayer.push({
                                'event': 'interaction',
                                'eventCategory': 'contact us',
                                'eventAction': 'send message',
                                'eventLabel': _self.category.val() + " " + _self.subCategory.val() + " " + subject
                            });
                        }
                        // End customization

                        if (res.indexOf("<script") === 0) {
                            $form.append(res);
                        } else {
                            $form.html(res);
                        }

                        $form.find(".field-validation-error").not(":empty").closest(".form-group").addClass("has-error");

                        var token = $form.find("[name=__RequestVerificationToken]").val();
                        $("[name=__RequestVerificationToken]").val(token);
                    }

                }, 100);

                this.checkTransition(_self.$container, function () {
                    _self.$container.css('height', 'auto').removeClass('transition');
                    _self.busy = false;
                })
            } else {
                this.$modal.one('hidden.bs.modal', function () {
                    setTimeout(function () { _self.$modalForm.modal() }, 200)
                }).modal('hide');
            }
        },

        hide: function () {
            var formHeight = this.$container.children().first().outerHeight(true),
                _self = this;

            this.busy = true;

            $('.g-recaptcha').closest('div').remove();

            this.$button.toggleClass('hide');
            this.$container.css({ 'height': formHeight }).addClass('transition');

            setTimeout(function () {
                _self.$container.addClass('closed');
                _self.$el.removeClass(_self.loadedClass);
            }, 100);

            this.checkTransition(_self.$container, function () {
                _self.$container.css({ 'height': '' }).removeClass('transition').empty();
                _self.busy = false;
            })
        },

        checkTransition: function ($el, fn) {
            if ($.support.transition) {
                $el.one($.support.transition.end, function (e) {
                    fn();
                });
            } else {
                fn();
            }
        },

        throttle: function (callback, limit) {
            var wait = false;
            return function () {
                if (!wait) {
                    callback.call();
                    wait = true;
                    setTimeout(function () {
                        wait = false;
                    }, limit);
                }
            }
        }

    }

    // Plugin declaration/assignment
    $.fn[_namespace] = function (options, arguments) {
        var args = arguments,
            method = null;
        options || (options = {});

        // if options is a string/method - set method and reset options to args[1]
        if (typeof options === 'string') {
            method = options;

            if (typeof args != 'undefined') {
                args = Array.prototype.slice.call(args, 0);
            } else {
                args = [];
            }
        }

        return this.each(function () {
            var data = $(this).data(_namespace);

            if (!data) {
                data = new ContactUsForm(this, $.extend({}, ContactUsForm.defaults, options));
            }

            if (method) {
                if (ContactUsForm.prototype[method]) {
                    data[method].apply(data, args);
                } else {
                    console.error('Method ' + method + ' does not exist in ' + _namespace);
                }
            }
        });
    };

    /**
     * Data api definition/setup/instantiation
     */
    $(function () {
        $('.js-contact-form').each(function () {
            var options = {};

            for (opt in ContactUsForm.defaults) {
                $(this).data(opt) && (options[opt] = $(this).data(opt));
            }
            $(this).ContactUsForm(options);
        });
    });

})(jQuery);

(function ($) {

    var _namespace = 'ContactUsList';

    /**
     * Constructor method
     */
    var ContactUsList = this.ContactUsList = function (el, options) {
        this.$el = $(el);

        this.options = options;
        this.$selects = this.$el.find('select');
        this.$contactContainer = $(".js-contacts-container");
        this.$spinner = $('div.spinner');
        this.optionJson = window.CategoryList;
        this.xhr = null;

        this._init();

        // set data on the object
        this.$el.data(_namespace, this);
    };

    ContactUsList.defaults = {};

    ContactUsList.prototype = {
        _init: function () {
            $(this.$selects[1]).closest('.col-md-4').hide();

            this.loadCategories(this.optionJson, this.$selects.first());

            this._bindEvent();
        },

        _bindEvent: function () {
            var _self = this;

            this.$selects.on('selectmenuchange',
                function (e) {
                    e.preventDefault();
                    var optionSelectedIndex;

                    _self.$contactContainer.empty();

                    if ($(this).val() == "") {

                        if ($(this).hasClass('categories')) {
                            $(_self.$selects[1]).closest('.col-md-4').hide();
                            return false;
                        }

                        return false;
                    }

                    optionSelectedIndex = $(this).find(':selected').index() - 1;



                    if ($(this).hasClass('categories') && _self.optionJson[optionSelectedIndex].SubCategory != null) {
                        //More specifc option Category + sub category
                        $(_self.$selects[1]).closest('.col-md-4').show();
                        return _self.loadCategories(_self.optionJson[optionSelectedIndex].SubCategory, $(_self.$selects[1]));
                    } else if ($(this).hasClass('categories') && !$(this).hasClass('sub-categories')) { //
                        $(_self.$selects[1]).val("").selectmenu("refresh");
                        $(_self.$selects[1]).closest('.col-md-4').hide();
                        return _self.loadContactList();
                    }
                    else if ($(this).hasClass('sub-categories')) {
                        return _self.loadContactList();
                    }

                    return true;
                    
                });
        },

        loadCategories: function (json, $el) {
            $el.find('option').not(':first').remove();

            $.each(json,
                function (key, value) {
                    var $option = $('<option>',
                        {
                            value: value.Id,
                            text: value.TagName
                        });

                    $el.append($option);
                });

            $el.selectmenu("refresh");
        },

        loadContactList: function () {
            var _self = this;

            if (this.xhr != null) {
                this.xhr.abort();
            }


            this.xhr = $.ajax({
                url: _self.$el.attr('action'),
                method: _self.$el.attr('method'),
                data: _self.$el.serializeArray(),
                dataType: 'html',
                cache: false,
                beforeSend: function () {
                    _self.$spinner.show();
                },
                success: function (html) {
                    var $contactForm;
                    debugger;

                    _self.$contactContainer.empty().html(html);
                    $contactForm = $('.js-contact-form');

                    $contactForm.each(function () {
                        var options = {};

                        for (opt in ContactUsForm.defaults) {
                            $(this).data(opt) && (options[opt] = $(this).data(opt));
                        }
                        $(this).ContactUsForm(options);
                    });

                    if ($contactForm.length == 1) {
                        // console.log("552");
                        // $contactForm.find('.btn.btn-secondary').trigger('click');
                        var emailAddress = $('.contact-us-loadform').attr('data-email-id');
                        var personName = $('.contact-us-loadform').attr('data-name');
                        $('.hiddenEmail')[0].value = emailAddress;
                        $('.hiddenPersonName')[0].value = personName;
                        $('.contact-us-form-section').removeClass('hide');
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".contact-us-form-section").offset().top
                        }, 1000);
                      // alert('hi1');
                        $('.form-control.text-box.dropdown').find('option[selected="selected"]').each(function () { $(this).prop('selected', true); });
                    }
                },
                complete: function () {
                    _self.xhr = null;
                    _self.$spinner.hide();
                    // console.log("559");

                   


                }
            });
        }
    }

    // Plugin declaration/assignment
    $.fn[_namespace] = function (options, arguments) {
        var args = arguments,
            method = null;
        options || (options = {});

        // if options is a string/method - set method and reset options to args[1]
        if (typeof options === 'string') {
            method = options;

            if (typeof args != 'undefined') {
                args = Array.prototype.slice.call(args, 0);
            } else {
                args = [];
            }
        }

        return this.each(function () {
            var data = $(this).data(_namespace);

            if (!data) {
                data = new ContactUsList(this, $.extend({}, ContactUsList.defaults, options));
            }

            if (method) {
                if (ContactUsList.prototype[method]) {
                    data[method].apply(data, args);
                } else {
                    console.error('Method ' + method + ' does not exist in ' + _namespace);
                }
            }
        });
    };
})(jQuery);

(function ($) {

    var _namespace = 'ContactUsFormList';
    var optionSelectedValue; //Global variable for storing selected category 
    /**
     * Constructor method
     */
    var ContactUsFormList = this.ContactUsFormList = function (el, options) {
        this.$el = $(el);

        this.options = options;
        this.$selects = this.$el.find('select');
        this.$contactContainer = $(".js-contacts-container");
        this.$spinner = $('.spinner');
        this.optionJson = window.CategoryList;
        this.xhr = null;

        this._init();

        // set data on the object
        this.$el.data(_namespace, this);
    };

    ContactUsFormList.defaults = {};

    ContactUsFormList.prototype = {
        _init: function () {
            $(this.$selects[1]).closest('.col-md-4').hide();

            this.loadCategories(this.optionJson, this.$selects.first());

            this._bindEvent();
        },

        _bindEvent: function () {
            var _self = this;

            this.$selects.on('selectmenuchange',
                function (e) {
                    e.preventDefault();
                    var optionSelectedIndex;

                    _self.$contactContainer.empty();

                    if ($(this).val() == "") {

                        if ($(this).hasClass('categories')) {
                            $(_self.$selects[1]).closest('.col-md-4').hide();
                            return false;
                        }

                        return false;
                    }

                    optionSelectedIndex = $(this).find(':selected').index() - 1;
                    optionSelectedValue = $(this).find(':selected').val(); //this variable will store the selected category

                    if ($(this).hasClass('categories') && _self.optionJson[optionSelectedIndex].SubCategory != null) {
                        //More specifc option Category + sub category
                        $(_self.$selects[1]).closest('.col-md-4').show();
                        return _self.loadCategories(_self.optionJson[optionSelectedIndex].SubCategory, $(_self.$selects[1]));
                    } else if ($(this).hasClass('categories') && !$(this).hasClass('sub-categories')) { //
                        $(_self.$selects[1]).val("").selectmenu("refresh");
                        $(_self.$selects[1]).closest('.col-md-4').hide();
                        return _self.loadContactList();
                    }
                    else if ($(this).hasClass('sub-categories')) {
                        return _self.loadContactList();
                    }

                    return true;
                });
        },

        loadCategories: function (json, $el) {
            $el.find('option').not(':first').remove();

            $.each(json,
                function (key, value) {
                    var $option = $('<option>',
                        {
                            value: value.Id,
                            text: value.TagName
                        });

                    $el.append($option);
                });

            $el.selectmenu("refresh");
        },

        loadContactList: function () {


            $('.hiddenEmail')[0].value = ""
            //$('.hiddenPersonName')[0].value = ""
            $('.contact-us-form-section').addClass('hide');

            var _self = this;

            if (this.xhr != null) {
                this.xhr.abort();
            }


            this.xhr = $.ajax({
                url: _self.$el.attr('action'),
                method: _self.$el.attr('method'),
                data: _self.$el.serializeArray(),
                dataType: 'html',
                cache: false,
                beforeSend: function () {
                    _self.$spinner.show();
                },
                success: function (html) {
                    var $contactForm;
                    // console.log(html);

                    if (document.getElementById("formconfiguration") != null && document.getElementById("formconfiguration") != 'undefined') {
                        var formConfiguration = document.getElementById("formconfiguration").value;
                        var FormURL = document.getElementById("FormURL").value;
                        if (formConfiguration != null && formConfiguration != "" && formConfiguration != 'undefined') {
                            var jsFormConfiguration = JSON.parse(formConfiguration);
                            if (jsFormConfiguration != null) {
                                var value = getValues(jsFormConfiguration, optionSelectedValue);
                                if (value != null && value != "" && value != 'undefined') {
                                    var URL = FormURL + "?category=" + optionSelectedValue + "&formid=" + value;
                                    window.open(URL, '_blank');
                                    return false;
                                }
                            }
                        }
                    }
                   

                    _self.$contactContainer.empty().html(html);
                    $contactForm = $('.js-contact-form');

                    $contactForm.each(function () {
                        var options = {};

                        for (opt in ContactUsForm.defaults) {
                            $(this).data(opt) && (options[opt] = $(this).data(opt));
                        }
                        //$(this).ContactUsForm(options);
                    });
                    
                    if ($contactForm.length == 1) {
                        //$contactForm.find('.btn.btn-secondary').trigger('click');
                        var emailAddress = $('.contact-us-loadform').attr('data-email-id');
                        var personName = $('.contact-us-loadform').attr('data-name');
                        $('.hiddenEmail')[0].value = emailAddress;
                        $('.hiddenPersonName')[0].value = personName;
                        //Set Category Field Value With Category Item Name from Sitecore 
                        sethiddenCategoryField(optionSelectedValue);
                        $('.contact-us-form-section').removeClass('hide');
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".contact-us-form-section").offset().top
                        }, 1000);
                       // alert('hi2');
                        $('.form-control.text-box.dropdown').find('option[selected="selected"]').each(function () { $(this).prop('selected', true); });

                    } else if ($contactForm.length > 1) {
                        sethiddenCategoryField(optionSelectedValue);
                    }
                },

                complete: function () {
                    _self.xhr = null;
                    _self.$spinner.hide();
                }

            });


            function getObjects(obj, key, val) {
                var objects = [];
                for (var i in obj) {
                    if (!obj.hasOwnProperty(i)) continue;
                    if (typeof obj[i] == 'object') {
                        objects = objects.concat(getObjects(obj[i], key, val));
                    } else
                        //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
                        if (i == key && obj[i] == val || i == key && val == '') { //
                            objects.push(obj);
                        } else if (obj[i] == val && key == '') {
                            //only add if the object is not already in the array
                            if (objects.lastIndexOf(obj) == -1) {
                                objects.push(obj);
                            }
                        }
                }
                return objects;
            }

            //return an array of values that match on a certain key
            function getValues(obj, key) {
                var objects = [];
                for (var i in obj) {
                    if (!obj.hasOwnProperty(i)) continue;
                    if (typeof obj[i] == 'object') {
                        objects = objects.concat(getValues(obj[i], key));
                    } else if (i == key) {
                        objects.push(obj[i]);
                    }
                }
                return objects;
            }

            //return an array of keys that match on a certain value
            function getKeys(obj, val) {
                var objects = [];
                for (var i in obj) {
                    if (!obj.hasOwnProperty(i)) continue;
                    if (typeof obj[i] == 'object') {
                        objects = objects.concat(getKeys(obj[i], val));
                    } else if (obj[i] == val) {
                        objects.push(i);
                    }
                }
                return objects;
            }

            function sethiddenCategoryField(optionSelectedValue) {

                if (typeof optionSelectedValue === 'string' && optionSelectedValue !== '') {
                    $('.hiddenCategoryField')[0].value = optionSelectedValue;
                }

            }



        }
    }

    // Plugin declaration/assignment
    $.fn[_namespace] = function (options, arguments) {
        var args = arguments,
            method = null;
        options || (options = {});

        // if options is a string/method - set method and reset options to args[1]
        if (typeof options === 'string') {
            method = options;

            if (typeof args != 'undefined') {
                args = Array.prototype.slice.call(args, 0);
            } else {
                args = [];
            }
        }

        return this.each(function () {
            var data = $(this).data(_namespace);

            if (!data) {
                data = new ContactUsFormList(this, $.extend({}, ContactUsFormList.defaults, options));
            }

            if (method) {
                if (ContactUsFormList.prototype[method]) {
                    data[method].apply(data, args);
                } else {
                    console.error('Method ' + method + ' does not exist in ' + _namespace);
                }
            }
        });
    };
})(jQuery);