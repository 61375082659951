var mainSearch = {
    mainHeader: $('.main-header'),
    searchContainer: $('.main-search--header'),
    window: $(window),
    input: $('#inputSearchBox'),


    /**
	 * showSearch
	 */
    showSearch: function () {
        window.scrollTo(0, 0);
        if ($('#main-container').hasClass("overlayIsActive")) {
            $('#main-container').removeClass('overlayIsActive');
        }
        mainSearch.mainHeader.addClass('show-search');
        $('html').css({ 'overflow-y':'hidden'});

    },

    /**
	 * inputFocus
	 */
    inputFocus: function () {
        //mainSearch.input.focus();
        mainSearch.input.trigger("focus");
       
    },

    inputFocusOut: function () {
        //mainSearch.input.blur();
        mainSearch.input.trigger("blur");
    },

    checkTransition: function ($el, fn) {
        if ($.support.transition) {
            $el.one($.support.transition.end, function (e) {
                fn();
            });
        } else {
            fn();
        }
    },

    /**
	 * hideSearch
	 */
    hideSearch: function () {
        mainSearch.mainHeader.removeClass('show-search');
        mainSearch.window.off('resize.search');
        $('html').css({ 'overflow-y': 'scroll' });
    }
};

var $searchBth = $('.main-search .ms__btn'),
    $mainNav = $('.main-nav'),
    $closeBtn = $('.main-search--header .ms__btn'),
    $tray = $('.hamburger-button'),
    $body = $('body');

$('document').ready(function () {
    var $window = $(window),
        $form = mainSearch.searchContainer.find('form'),
        formOpen = false;

    $(document).on('click', function (e) {
        var container = mainSearch.searchContainer;

        if (formOpen && !container.is(e.target) && container.has(e.target).length === 0) {
            mainSearch.hideSearch();
            mainSearch.searchContainer.removeClass('transitioning');
            formOpen = false;
        }

    });

    Respond.to({
        'media': '(max-width: 1199px)',
        'namespace': '1200_search',
        'fallback': 'else',
        'if': function () {
            $searchBth.off('click.headSearch');
            $closeBtn.off('click.headSearch');
            $window.off('resize.search');
            mainSearch.hideSearch();
            mainSearch.searchContainer.css({
                'width': '',
                'right': ''
            });
            $form.css({
                'width': ''
            });
            formOpen = false;

            $searchBth.on('click.headSearch', function (e) {

                if (mainSearch.mainHeader.hasClass('show-search')) {
                    mainSearch.hideSearch();

                    mainSearch.checkTransition(mainSearch.searchContainer, function () {
                        formOpen = false;
                        mainSearch.inputFocusOut();
                    });
                } else {
                   // $('.mobile-nav button.ms__btn').on('click', function () { window.scrollTo(0, 0) });  
                    mainSearch.showSearch();
                    mainSearch.inputFocus();
                    //alert('open');
                    if ($body.hasClass('sideNavIsOpen')) {
                        $tray.trigger('click.tray')
                    }

                    mainSearch.checkTransition(mainSearch.searchContainer, function () {
                        formOpen = true;
                        mainSearch.inputFocus();
                    });
                }

            });

            $closeBtn.on('click.headSearch', function (e) {
                mainSearch.hideSearch();

                mainSearch.checkTransition(mainSearch.searchContainer, function () {
                    formOpen = false;
                });
            });


        },
        'else': function () {

            $searchBth.off('click.headSearch');
            $closeBtn.off('click.headSearch');
            mainSearch.hideSearch();
            formOpen = false;

            $closeBtn.on('click.headSearch', function (e) {
                mainSearch.searchContainer.addClass('transitioning');

                setTimeout(function () {
                    mainSearch.searchContainer.css('width', '');
                }, 150)

                mainSearch.checkTransition(mainSearch.searchContainer, function () {
                    mainSearch.hideSearch();
                    mainSearch.searchContainer.removeClass('transitioning');
                    formOpen = false;
                    mainSearch.inputFocusOut();
                });
            });

            $searchBth.on('click.headSearch', function (e) {
                var width = $(this).offset().left - $mainNav.offset().left,
                    that = $(this);

                e.preventDefault();

                $form.width(width + that.width() + 'px');
                mainSearch.searchContainer.addClass('transitioning').css('right', $window.width() - 2 - ($(this).offset().left + $(this).outerWidth(true)));

                mainSearch.showSearch();

                setTimeout(function () {
                    mainSearch.searchContainer.width(width + 12 + that.width() + 'px');
                }, 150)

                mainSearch.checkTransition(mainSearch.searchContainer, function () {
                    mainSearch.searchContainer.removeClass('transitioning')
                    formOpen = true;
                    mainSearch.inputFocus();
                });

                $window.on('resize.search', function () {
                    width = that.offset().left - $mainNav.offset().left;

                    $form.width(width + that.width() + 'px');
                    mainSearch.searchContainer.width(width + that.width() + 2 + 'px');

                    mainSearch.searchContainer.css('right', $window.width() - 2 - (that.offset().left + that.outerWidth(true)));
                });
            });
        }
    });

});
