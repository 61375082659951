var videoPlayer = {
    /**
     * Initialize all videos players
     */
    videosInit: function () {
        // 01 - set volume to 70%
        try {
            $('video').get(0).volume = 70 / 100;
        } catch (e) {
        }

        $('.video-player').addClass('isPaused');

        // 02 - Inject duration
        window.setInterval(function (t) {
            $('.video-player').each(function () {
                var currentVideo = $(this).find('video').get(0);

                if (typeof currentVideo != 'undefined' && currentVideo.readyState > 0) {
                    $(this).find('.vp__duration').text(videoPlayer.timeFormat(currentVideo.duration));
                    clearInterval(t);
                }
            });
        }, 500);
    },



    /**
     * Update timer of current video 
     * @param {obj} e 
     */
    timeUpdate: function (e) {
        var activeContainer = $(e.target).closest('.video-player');
        var activeVideo = activeContainer.find('video').get(0);

        activeVideo.ontimeupdate = function () {
            var currentPos = activeVideo.currentTime;
            var percentage = 100 * currentPos / activeVideo.duration;

            activeContainer.find('.vp__time-bar').css('width', percentage + '%');
            activeContainer.find('.vp__current').text(videoPlayer.timeFormat(currentPos));
            activeContainer.find('.vp__buffer-bar').attr('aria-valuenow', Math.ceil(percentage));
        };
    },



    /**
     * Adds two numbers
     * @param {Number} seconds 
     * @return {string} 60:60
     */
    timeFormat: function (seconds) {
        var m = Math.floor(seconds / 60) < 10 ? '0' + Math.floor(seconds / 60) : Math.floor(seconds / 60);
        var s = Math.floor(seconds - (m * 60)) < 10 ? '0' + Math.floor(seconds - (m * 60)) : Math.floor(seconds - (m * 60));
        return m + ':' + s;
    },



    /**
     * Pause all videos players
     */
    pauseAllVideos: function (e) {
        $('video').each(function () {
            $(this).get(0).pause();
            $(this).parent().addClass('isPaused');
            $(this).parent().removeClass('isPlaying');
            $(this).siblings('.rounded-play-button').eq(0).show();
        });
    },



    /**
     * Pause current video
     * @param {obj} e 
     */
    pauseVideo: function (e) {
        var buttonTarget = e.target;

        $(buttonTarget).closest('.video-player').find('video').get(0).pause();
        $(buttonTarget).closest('.video-player').addClass('isPaused');
        $(buttonTarget).closest('.video-player').removeClass('isPlaying');
        $(buttonTarget).closest('.video-player').find('.rounded-play-button').show();
        $(buttonTarget).closest('.video-player').find('.vp__bottom-control .vp__play').removeClass('hide');
        $(buttonTarget).closest('.video-player').find('.vp__pause').addClass('hide');
    },



    /**
     * Play current video
     * @param {obj} e 
     */
    playCurrentVideo: function (e) {
        var buttonTarget = e.target;
        var activeContainerVideo = $(buttonTarget).closest('.video-player');
        videoPlayer.hideOverlay(e);

        if ($(activeContainerVideo).hasClass('isPlaying')) {
            videoPlayer.hideOverlay(e);
            videoPlayer.pauseVideo(e);
        } else {
            videoPlayer.pauseAllVideos(e);

            $(buttonTarget).closest('.video-player').find('video').get(0).play();
            $(buttonTarget).closest('.video-player').removeClass('isPaused');
            $(buttonTarget).closest('.video-player').addClass('isPlaying');
            $(buttonTarget).closest('.video-player').find('.rounded-play-button').hide();
            $(buttonTarget).closest('.video-player').find('.vp__bottom-control .vp__play').addClass('hide');
            $(buttonTarget).closest('.video-player').find('.vp__pause').removeClass('hide');

            videoPlayer.showOverlay(e);
        }
    },



    /**
     * Active fullscreen mode
     * @param {obj} e
     */
    fullScreenMode: function (e) {
        // http://stackoverflow.com/questions/19357854/html5-exiting-video-fullscreen
        var buttonTarget = e.target;
        var activeContainerVideo = $(buttonTarget).closest('.video-player');
        var activeVideo = $(buttonTarget).closest('.video-player').find('video').get(0);
        var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        var event = state ? 'FullscreenOn' : 'FullscreenOff';

        if (event == 'FullscreenOn') {
            activeContainerVideo.addClass('isFullscreen');
        } else if (event == 'FullscreenOff') {
            activeContainerVideo.removeClass('isFullscreen');
        }

        if (activeContainerVideo.hasClass('isFullscreen')) {
            activeContainerVideo.find('.vp__no-fullscreen').addClass('hide');
            activeContainerVideo.find('.vp__fullscreen').removeClass('hide');

            if (activeVideo.exitFullscreen) {
                document.exitFullscreen();
            } else if (activeVideo.mozCancelFullScreen) {
                activeVideo.mozCancelFullScreen();
            } else if (activeVideo.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (activeVideo.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            activeContainerVideo.find('.vp__no-fullscreen').removeClass('hide');
            activeContainerVideo.find('.vp__fullscreen').addClass('hide');

            if (activeVideo.requestFullscreen) {
                activeVideo.requestFullscreen();
            } else if (activeVideo.mozRequestFullScreen) {
                activeVideo.mozRequestFullScreen();
            } else if (activeVideo.webkitEnterFullScreen) {
                activeVideo.webkitEnterFullScreen();
            } else if (activeVideo.msRequestFullscreen) {
                activeVideo.msRequestFullscreen();
            }
        }
    },



    /**
     * add/remove class isFullscreen on player fullScreenClass
     * @param {obj} e
     */
    fullScreenClass: function (e) {
        var buttonTarget = e.target;
        var activeContainerVideo = $(buttonTarget).closest('.video-player');
        var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        var event = state ? 'FullscreenOn' : 'FullscreenOff';

        if (event == 'FullscreenOn') {
            activeContainerVideo.addClass('isFullscreen');
        } else if (event == 'FullscreenOff') {
            activeContainerVideo.removeClass('isFullscreen');
        }
    },



    /**
     * Mute current video
     * @param {obj} e
     */
    muteVideo: function (e) {
        var buttonTarget = e.target;
        var activeContainerVideo = $(buttonTarget).closest('.video-player');
        var activeVideo = activeContainerVideo.find('video').get(0);

        activeVideo.muted = !activeVideo.muted;

        if (activeVideo.muted) {
            activeContainerVideo.find('.vp__mute').removeClass('hide');
            activeContainerVideo.find('.vp__sound').addClass('hide');
            activeContainerVideo.find('.vp__volume-bar').css('width', 0);
        } else {
            activeContainerVideo.find('.vp__mute').addClass('hide');
            activeContainerVideo.find('.vp__sound').removeClass('hide');
            activeContainerVideo.find('.vp__volume-bar').css('width', activeVideo.volume * 100 + '%');
        }
    },



    /**
     * Update progress bar
     * @param {obj} e
     */
    updateProgressBar: function (e, timeDirection) {
        var activeContainerVideo = $(e.target).closest('.video-player');
        var activeVideo = activeContainerVideo.find('video').get(0);
        var progress = activeContainerVideo.find('.vp__progress-bar');
        var progressBuffer = activeContainerVideo.find('.vp__buffer-bar');
        var percentage = 100 * (e.pageX - progress.offset().left) / progress.width();
        var actualPercentage = parseInt(progressBuffer.attr('aria-valuenow'));

        if (timeDirection === "down") {
            percentage = actualPercentage - 10;
        } else if (timeDirection === "up") {
            percentage = actualPercentage + 10;
        }

        if (percentage > 100) {
            percentage = 100;
        }

        if (percentage < 0) {
            percentage = 0;
        }

        activeContainerVideo.find('.vp__time-bar').css('width', percentage + '%');
        activeVideo.currentTime = activeVideo.duration * percentage / 100;
    },



    /**
     * Update volume bar
     * @param {obj} e
     * @param {string} volumeDirection
     */
    updateVolume: function (e, volumeDirection) {
        var activeContainerVideo = $(e.target).closest('.video-player');
        var activeVideo = activeContainerVideo.find('video').get(0);
        var volume = activeContainerVideo.find('.vp__volume');
        var percentage = 100 * (e.pageX - volume.offset().left) / volume.width();
        var actualPercentage = parseInt(volume.attr('aria-valuenow'));

        if (volumeDirection === "down") {
            percentage = actualPercentage - 10;
        } else if (volumeDirection === "up") {
            percentage = actualPercentage + 10;
        }

        if (percentage > 100) {
            percentage = 100;
        }

        if (percentage < 0) {
            percentage = 0;
        }

        // 01 - update volume bar and video volume
        activeContainerVideo.find('.vp__volume-bar').css('width', percentage + '%');
        activeVideo.volume = percentage / 100;
        activeContainerVideo.find('.vp__volume ').attr('aria-valuenow', Math.ceil(percentage));

        // 02 - change sound icon based on volume
        if (activeVideo.volume == 0) {
            activeContainerVideo.find('.vp__sound ').addClass('hide');
            activeContainerVideo.find('.vp__mute ').removeClass('hide');
            activeVideo.muted = true;
        } else if (activeVideo.volume > 0.0000001) {
            activeContainerVideo.find('.vp__sound ').removeClass('hide');
            activeContainerVideo.find('.vp__mute ').addClass('hide');
            activeVideo.muted = false;
        } else {
            activeContainerVideo.find('.vp__sound ').removeClass('hide');
            activeContainerVideo.find('.vp__mute ').addClass('hide');
            activeVideo.muted = false;
        }
    },



    /**
     * showOverlay
     * @param {obj} e
     */
    showOverlay: function (e) {
        var activeContainerVideo = $(e.target).closest('.video-player');
        var activeVideo = activeContainerVideo.find('video').get(0);

        $('body').append('<div class="vp__overlay"></div>');

        $('.vp__overlay').css({
            'position': 'absolute',
            'width': 100 + '%',
            'height': $(document).height(),
            'background': '#000',
            'opacity': 0.9,
            'top': 0,
            'left': 0,
            'z-index': 999
        });

        activeContainerVideo.css({
            'z-index': 1000
        });
    },



    /**
     * hideOverlay
     * @param {obj} e
     */
    hideOverlay: function (e) {
        var activeContainerVideo = $(e.target).closest('.video-player');
        var activeVideo = activeContainerVideo.find('video').get(0);

        $('.vp__overlay').remove();

        activeContainerVideo.css({
            'z-index': 1
        });
    }
};

if ($('.video-player').length) {
    videoPlayer.videosInit();

    $('.vp__play-pause-button, .rounded-play-button, video').on("click",function (e) {
        videoPlayer.playCurrentVideo(e);
        videoPlayer.timeUpdate(e);
    });

    $('.vp__close').on("click",function (e) {
        videoPlayer.hideOverlay(e);
        videoPlayer.pauseVideo(e);
    });

    $(document).keyup(function (e) {
        // 27 = escape key
        if (e.which == 27) {
            videoPlayer.hideOverlay(e);
            videoPlayer.pauseAllVideos(e);
        }
    });

    $('.vp__mute-button').on("click",function (e) {
        videoPlayer.muteVideo(e);
    });

    $('.vp__progress-bar').mousemove(function (e) {
        if (e.buttons == 1) {
            videoPlayer.updateProgressBar(e, undefined);
        }
    });

    $('.vp__progress-bar').on('mouseup', function (e) {
        videoPlayer.updateProgressBar(e, undefined);
    });

    $(".vp__buffer-bar").on("keydown", function (e) {
        // 37 = left key
        // 39 = right key
        if (e.which == 37) {
            videoPlayer.updateProgressBar(e, "down");
        } else if (e.which == 39) {
            videoPlayer.updateProgressBar(e, "up");
        }
    });

    $('.vp__volume').mousemove(function (e) {
        if (e.buttons == 1) {
            videoPlayer.updateVolume(e, undefined);
        }
    });

    $('.vp__volume').on('mouseup', function (e) {
        videoPlayer.updateVolume(e, undefined);
    });

    $(".vp__volume").on("keydown", function (e) {
        // 37 = left key
        // 39 = right key
        if (e.which == 37) {
            videoPlayer.updateVolume(e, "down");
        } else if (e.which == 39) {
            videoPlayer.updateVolume(e, "up");
        }
    });

    $('.vp__full-screen-button').on('click', function (e) {

        videoPlayer.fullScreenMode(e);
        videoPlayer.pauseAllVideos(e);
        videoPlayer.playCurrentVideo(e);
        videoPlayer.timeUpdate(e);
    });

    // Entering/Exiting fullscreen mode
    $('video').bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
        videoPlayer.fullScreenClass(e);
    });


    $('.video-player *').focus(function () {
        $('.video-player').addClass('isOnFocus');
    }).blur(function () {
        $('.video-player').removeClass('isOnFocus');
    });
}

